/* make the customizations */
$theme-colors: (
    'info': tomato,
    'danger': teal,
);

@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');

/* html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*/

$primary-color: #f18505;
$secondary-color: #703b20;
$third-color: #eae0d9;
$fourth-color: yellow;
$fifth-color: #487837;

.primary-color {
    color: $primary-color;
}

.secondary-color {
    color: $secondary-color;
}

.third-color {
    color: $third-color;
}

.dd {
    background-color: cyan;
}

.text-error {
    color: #cc0102;
}

.btn {
    font-family: 'Josefin Sans', sans-serif;
}

.btn-primary {
    background-color: #d8896e;
    color: white;
    border-radius: 5px;
    border: 0;
}

p {
    a {
        color: $primary-color;
        &:hover {
            color: $fourth-color;
            text-decoration: none;
        }
    }
}

body {
    margin: 0;
    // background-color: #eae0d9;
    // background-color: #462717;
    font-size: 0.9rem;
    overflow-x: hidden;
    font-family: 'Josefin Sans', sans-serif;
}

// button {
//     // border: none;
//     border-color: #487837 !important;
//     background-color: #487837 !important;
// }

.btn-link {
    color: #d8896e;
}

.btn-link:hover,
.btn-link:focus {
    color: #d8896e !important;
    text-decoration: none;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background-color: #d8896e !important;
}

.logoImage {
    max-width: 250px;
}

.contentBoxHeader {
    border-bottom: 1px solid white;
    margin-bottom: 20px;
    padding-bottom: 20px;
    color: white;
}

.contentBoxBody {
    color: white;
    font-size: 15px;
    margin-top: 20px;
}

.contentBoxTitle {
    color: brown;
    font-size: 1.2em;
}

.introHeading {
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
}

.bodyContent {
    font-size: 18px;
}

.image {
    position: relative !important;
    left: 0;
}

.mt-50 {
    margin-top: 50px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mt-100 {
    margin-top: 100px;
}

.mb-100 {
    margin-bottom: 100px;
}

.paddingBox {
    padding: 30px;
}

.primaryButton {
    background-color: #d8896e;
    border-color: #d8896e;
    width: 200px;

    &:hover {
        background-color: #d8896e;
        border-color: #d8896e;
        opacity: 0.8;
    }
}

.contentBox {
    // background: linear-gradient(#bd9681 0%, rgba(189, 150, 129, 0.7) 100%);
    // border-radius: 9px;
    overflow: hidden;
}

.container-center {
    height: 580px;
    position: relative;
    // border: 3px solid green;

    .center {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

.mt-large {
    margin-top: 120px;
}

.mb-large {
    margin-bottom: 120px;
}

.signupText {
    margin-left: 200px;
}

.justify-content-md-center {
    justify-content: center;
}

@media only screen and (max-width: 1000px) {
    .notfocused {
        display: none;
    }
    #slideLeft {
        display: inline-block !important;
    }
    #slideRight {
        display: inline-block !important;
    }
}

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 600px) {
    .banner {
        text-align: center;
        margin-top: 10vh;
        .heading {
            margin: 0;
        }
    }
}

@media only screen and (max-width: 500px) {
    body {
        font-size: 0.7rem !important;
    }
}

@media only screen and (max-width: 400px) {
}

@media only screen and (max-height: 620px) {
    .banner {
        margin-top: 2vh;
    }
}

@media only screen and (max-height: 580px) {
    .banner {
        margin-top: 0;
    }
}

.company {
    margin: 25px auto;
    .About,
    .Contact {
        .contentHeader {
            text-align: center;
        }
        margin: 25px auto;
        padding: 20px;
        min-height: 520px;
        max-width: 500px;
    }
}
