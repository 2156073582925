.flowerImage {
    position: absolute !important;
    z-index: -1;
    width: 400px;
}

.flowerImage1 {
    -ms-transform: rotate(-180deg); /* IE 9 */
    transform: rotate(-180deg);
    top: -80px;
    right: -90px;
}

.flowerImage2 {
    left: -110px;
    bottom: -85px;
}

.home {
    // background-color: #eae0d9;
    background-color: #e5e5e5;
}
