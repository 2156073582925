.forgotpassword-modal {
    .modal-content {
        background-color: #fdf7ee !important;
        // border-radius: 9px;

        .modal-body {
            h3 {
                color: #d8896e;
                font-family: Josefin Sans, 'sans-serif';
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: left;
                padding: 30px 0 30px 0;
            }
        }
        .form-group {
            font-family: Josefin Sans, 'sans-serif';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 2rem;
        }

        .form-control {
            border: none;
            border-bottom: 1px solid #e5e5e5;
            background-color: inherit;
        }
    }

    .modal-header {
        padding-bottom: 1rem !important;
        border-bottom: 0 !important;

        .modal-title {
            font-family: Josefin Sans, 'sans-serif';
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: center;
            // margin-left: 25px;
            margin: 50px 0 0 auto;
        }
    }

    .btn.btn-link {
        padding: 0;
    }

    .modal-footer {
        font-family: Josefin Sans, 'sans-serif';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        border-top: 0 !important;

        .forgot-password-text {
            font-size: 14px;
            margin-bottom: 40px;

            .btn-link {
                font-size: 14px;
            }
        }

        .submit-button {
            font-size: 20px;
            width: 150px;
        }

        .returning-customer {
            margin-top: 20px;
            font-size: 16px;
        }
    }

    .large-action-btn {
        width: 216px;
        height: 40px;
        font-size: 20px;
        margin-bottom: 30px;
        font-weight: bold;
    }

    .displayNone {
        display: none;
    }
}
