.menu-modal {
    a {
        color: inherit;
        vertical-align: middle;
    }
    :hover {
        color: inherit;
        text-decoration: none;
    }
    .menu-logout-btn {
        width: 100%;
    }
    .menu-icon {
        width: 70px;
    }
    .modal-content {
        background-color: #fdf7ee !important;
        // border-radius: 9px;

        .modal-body {
            h3 {
                color: #d8896e;
                font-family: Josefin Sans, 'sans-serif';
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: left;
                padding: 30px 0 40px 0;
            }
        }
        .form-group {
            font-family: Josefin Sans, 'sans-serif';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 2rem;
        }

        .form-control {
            border: none;
            border-bottom: 1px solid #e5e5e5;
            background-color: inherit;
        }

        .list-group-item {
            font-family: Josefin Sans;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            border: none;
            background-color: inherit;
            padding: 0 0 20px 0;
        }
    }

    .modal-header {
        padding-bottom: 0 !important;
        margin-bottom: 20px;

        .list-group-item {
            font-family: Josefin Sans;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            border: none;
            background-color: inherit;
            padding-bottom: 20px;
            margin-top: 10px;
            font-style: italic;
            color: #d8896e;
        }
    }

    .btn.btn-link {
        padding: 0;
    }

    .modal-footer {
        font-family: Josefin Sans, 'sans-serif';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        border-top: 0 !important;

        .forgot-password-text {
            font-size: 14px;
            margin-bottom: 40px;

            .btn-link {
                font-size: 14px;
            }
        }

        .submit-button {
            font-size: 20px;
            width: 150px;
        }

        .returning-customer {
            margin-top: 20px;
            font-size: 16px;
        }
    }

    .large-action-btn {
        width: 316px;
        height: 40px;
        font-size: 20px;
        margin-bottom: 30px;
        font-weight: bold;
    }
}
