.dashboard {
    margin-bottom: 100px;

    // .half-box {
    //     background: linear-gradient(#bd9681 0%, rgba(189, 150, 129, 0.7) 100%);
    //     border-radius: 9px;
    //     // overflow: hidden;
    // }

    .contentHeader {
        color: #272123;
        font-size: 20px;
        text-align: center;
        font-family: Josefin Sans, 'sans-serif';
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;
    }

    .body {
        // background-image: url('../../../images/flower_tiny_reverse.png');
        background-repeat: no-repeat;
        background-size: 200px;
        background-position: right -60px top -40px;

        margin: 20px;
        padding: 60px;
        border-radius: 9px;
        text-align: center;
        z-index: 2;

        p {
            margin-top: 20px;
        }
    }
}
