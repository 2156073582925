.resetpassword {
    background: #f2f2f2;

    .contentBoxHeader {
        color: #272123;
    }
    .contentBoxBody {
        color: #272123;
    }
}
