// .heroImage {
//     width: 100%;
//     min-height: 100vh;
//     background: url('../../../../images/sky_tiny.png');
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
//     color: white;
//     overflow: hidden;
// }

.hero {
    padding: 80px 0 80px;
}

.heroImage {
    max-width: 500px;
    min-width: 10px;
    position: absolute;
    right: 0px;
    top: -50px;
    z-index: 1;
}

.heroBox {
    background: #f9ebd5 40%;
    border-radius: 9px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 50px !important;
    padding-right: 50px !important;
    h2 {
        font-family: 'Josefin Sans', sans-serif;
        font-weight: 600;
        font-size: 32px;
        line-height: 32px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 30px;
    }
}

p {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #272123;
}

.clickable {
    cursor: pointer;
    color: #d8896e;
}

.swal-wide {
    width: 50% !important;
    max-width: 1000px !important;
    min-width: 400px !important;
}

.welcomeMessageButtonContainer {
    text-align: center;
}

@media only screen and (max-width: 1200px) {
    .heroImage {
        max-width: 450px;
    }
}

@media only screen and (max-width: 1000px) {
    .hero {
        padding-bottom: 0;
    }

    .heroImage {
        width: 75%;
        padding: 0;
        top: 0;
        left: 18%;
        position: relative;
        text-align: center;
        margin: 80px auto 60px;
    }

    .swal-wide {
        width: 80% !important;
    }
}

@media only screen and (max-width: 800px) {
    .swal-wide {
        width: 95% !important;
    }
}

@media only screen and (max-width: 600px) {
    .swal-wide {
        font-size: 0.8rem !important;
    }
    .swal-wide p {
        font-size: 0.8rem !important;
    }
}
