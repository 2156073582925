.howItWork {
    span.link {
        cursor: pointer;
        color: #d8896e;
    }

    // .col {
    .card {
        background: #f9ebd5 40%;

        .icon {
            display: block;
            margin: 0 auto 20px;
        }
    }
    // }

    .card-deck {
        .card:first-child {
            margin-left: 0;
        }
        .card:last-child {
            margin-right: 0;
        }
    }

    .title {
        font-family: 'Josefin Sans', sans-serif;
        font-weight: 600;
        font-size: 32px;
        line-height: 32px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 60px;
    }
}

@media only screen and (max-width: 1000px) {
    .card-deck {
        display: block !important;
        width: 100%;
        margin: 0 !important;
        .card {
            margin: 0 0 10px 0 !important;
        }
    }
}
