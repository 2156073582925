.step {
    min-width: 300px;
    .mainTitle {
        font-size: 36px;
        margin-top: 30px;
    }
    .subTitle {
        text-align: center;
        font-size: 18px;
    }
    .body {
        background-color: inherit;
        font-size: .8em;
    }
    .contentHeader {
        padding: 60px;
    }
    .medizate {
        p {
            font-size: 26px;
            text-align: center;
        }
    }
}

@media only screen and (max-width: 800px) {
    .body {
        padding: 20px 0 !important;
    }
  }
  @media only screen and (max-width: 500px) {
    .body {
        font-size: .6em !important;
        margin: 0 !important;
    }
  }
