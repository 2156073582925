footer {
    margin-top: 100px;
    color: #272123;
    padding: 20px 0;
    height: 80px;
    ul {
        padding: 0;
        list-style-type: none;
        li {
            display: inline;
            padding: 0 5px;
        }
    }
    .footerRight {
        text-align: right;
        margin-right: 20px;
    }
    .link {
        padding-top: 20px;
        margin-right: 30px;
    }
}

footer_bak {
    color: #462717;
    background-color: #eae0d9;
    padding: 20px;
    height: 80px;
    ul {
        list-style-type: none;
        li {
            display: inline;
            padding: 0 5px;
        }
    }
    .footerRight {
        position: absolute;
        right: 0;
        margin-right: 40px;
    }
}

@media only screen and (max-width: 500px) {
    footer {
        padding: 0px;
        ul {
            padding: 0px;
        }
    }
    .icon.social {
        width: 30px;
    }
    .footerRight {
        margin-right: 20px !important;
    }
}
