.header {
    a {
        color: inherit;
        vertical-align: middle;
    }
    :hover {
        color: inherit;
        text-decoration: none;
    }

    padding: 45px 0 45px 0;
    margin-bottom: 50px;
    font-size: 16px;

    nav {
        // text-align: right;
        margin: 30px 0px;

        font-weight: 200;

        .navLink {
            color: white;
            padding: 0px 20px;

            &:hover {
                text-decoration: none;
                color: yellow;
            }

            // &.primary {
            //     color: #f18505 !important;
            // }

            &.dark {
                color: #703b20 !important;
                font-weight: 500;
            }
        }
    }

    .menuButton {
        display: none;
    }
}

.logosection {
    margin: 5px 0 30px 0px;
    .logotext {
        color: white;
        .twf {
            font-size: 40px;
        }
    }
}

.menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 5;
    top: -9px;
    right: -9px;
    width: 200px;
    // height: 200px;
    background: rgba(0, 0, 0, 0.9);
    // background: rgba(80, 51, 36, 0.719);
    border-radius: 9px;
    padding-top: 8px;
    text-align: right;

    .menuLink {
        text-align: left;
        font-weight: bold;
        color: white;
        width: 200px;
        height: 50px;
        padding: 15px;
        border-top: solid 1px white;
    }
}

.header-button {
    margin: 26px 0;
    text-align: right;
    .header-signup {
        // border-radius: 0;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }
    .header-signin {
        // border-radius: 0;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
}

@media only screen and (max-width: 800px) {
    .logoImage {
        width: 150px;
    }
    .header {
        nav .navLink {
            font-size: 15px;
            padding: 0px 10px;
        }
    }
}

@media only screen and (max-width: 600px) {
    .logoImage {
        width: 150px;
    }
    .header {
        nav .navLink {
            font-size: 13px;
            padding: 0px 7px;
        }
    }
}

@media only screen and (max-width: 500px) {
    .logoImage {
        width: 150px;
    }
    .header {
        // padding: 15px 15px;
        // nav .navLink {
        //     display: none;
        // }
        // .menuButton {
        //     display: inline-block;
        //     width: 45px;
        // }
    }
}

@media only screen and (max-width: 400px) {
    .logoImage {
        width: 100px;
    }
}
