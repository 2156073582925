.checkpoint-modal {
    .modal-content {
        background-color: #fdf7ee !important;
        // border-radius: 9px;

        .modal-body {
            h3 {
                color: #d8896e;
                font-family: Josefin Sans, 'sans-serif';
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: left;
                padding: 30px 0 40px 0;
            }
        }
        .form-group {
            font-family: Josefin Sans, 'sans-serif';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 2rem;
        }

        .form-control {
            // border: none;
            // border-bottom: 1px solid #e5e5e5;
            color: #272123 !important;
            font-size: 18px;
            background-color: inherit;
            padding: 0.8rem 0 0.375rem 0.2rem;
        }

        .input-group-text {
            background-color: inherit;
            border-right: none;
        }
        .input-group {
            input {
                border-left: none;
            }
        }
    }

    .modal-header {
        padding-bottom: 1rem !important;
        border-bottom: 0 !important;

        .modal-title {
            font-family: Josefin Sans, 'sans-serif';
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: center;
            margin: 20px auto;
        }
    }

    .btn.btn-link {
        padding: 0;
    }

    .large-action-btn {
        width: 316px;
        height: 40px;
        font-size: 20px;
        margin-top: 30px;
        font-weight: bold;
    }
}
